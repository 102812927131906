import React from "react";
import "./NoName.scss";

function NoName(props) {
  return (
    <section className="hero">
      <div className="hero-body">
        <section className="NoName__container container">
          <div className="NoName__box box is-shadowless has-text-centered has-text-weight-bold">
            <small>DİJİTAL İNOVASYON VE YAPI TEKNOLOJİLERİ A.Ş. </small>
          </div>
          <div className="NoName__box box is-shadowless">
            Mimarlar, tasarımcılar ve mühendisler, fikirden üretime kadar
            sürekli etkileşim halindedirler. Amacımız bu süreçte karşılaştıkları
            sorunlara çözümler getirmektir.
          </div>
          <div className="NoName__box box is-shadowless">
            <strong>REVISE</strong> - Mimarların, tasarımcıların ve
            mühendislerin uzaktan birlikte doğru çalışmasını sağlayan bir
            işbirliği aracıdır.
            <a
              className="button is-fullwidth is-link is-outlined mt-3"
              href="https://getrevise.com"
            >
              getrevise.com
            </a>
          </div>
          <div className="NoName__box box is-shadowless">
            <strong>İletişim:</strong> 
            <p className="">merhaba@inovasyonofisi.com</p>
            <br />
            <br />
            <strong>Adres:</strong> 
            <br />
            <p className="">ODTÜ TEKNOKENT Bilişim İnovasyon Merkezi Mustafa Kemal Mh. Dumlupınar Blv. 280/G No: 1260. PK:06520 - ÇANKAYA/ ANKARA</p>
          </div>
          <div className="NoName__box box is-shadowless has-text-weight-bold">
            inovasyonofisi.com <br /><small className="has-text-weight-normal">Dijital İnovasyon ve Yapı
            Teknolojileri A.Ş.'nin resmi internet sitesidir</small>
          </div>
        </section>
      </div>
    </section>
  );
}

export default NoName;
